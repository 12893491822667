// 2xl: 24
// 2xs: 10
// 3xl: 30
// 4xl: 36
// 5xl: 48
// 6xl: 60
// 7xl: 72
// 8xl: 96
// 9xl: 128
// lg: 18
// md: 16
// sm: 14
// xl: 20
// xs: 12

// base: 0
// sm: 480
// md: 768
// lg: 992
// xl: 1280
// 2xl: 1536

.input--masked {
  border-style: solid; 
  min-height: 41px; 
  font-size: 1rem;

  &:active,
  &:focus-visible {
    outline: none;
  }
}

.input--error {
  border-color: rgb(220, 38, 38) !important;
}
