@tailwind base;
@tailwind components;
@tailwind utilities;

.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    letter-spacing: 0.4px;
    font-size: 18px;
    text-transform: uppercase;
    font-family: Roboto;
    color: #ffffff;
    font-weight: 900;
    max-height: 50px;
    height: 50px;
}

.icon-svg {
    fill: white;
    width: 25px;
    height: auto;
    margin-right: 16px;
}

.ordered-list {
    list-style: decimal;
    padding-left: 1.5rem;

    li {
        margin: 1rem 0;
    }
}

.unordered-list {
    list-style: disc;
    padding-left: 1.5rem;

    li {
        margin: 1rem 0;
    }
}


.lds-ring {
    /* change color here */
    color: #7395D4;
  }
  .lds-ring,
  .lds-ring div {
    box-sizing: border-box;
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }