@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

// 2xl: 24
// 2xs: 10
// 3xl: 30
// 4xl: 36
// 5xl: 48
// 6xl: 60
// 7xl: 72
// 8xl: 96
// 9xl: 128
// lg: 18
// md: 16
// sm: 14
// xl: 20
// xs: 12

// base: 0
// sm: 480
// md: 768
// lg: 992
// xl: 1280
// 2xl: 1536

$sm: 480px;
$md: 768px;
$lg: 992px;
$xl: 1280px;
$xxl: 1536px;

@mixin sm {
  @media screen and (min-width: #{$sm}) {
    @content;
  }
}
@mixin md {
  @media screen and (min-width: #{$md}) {
    @content;
  }
}
@mixin lg {
  @media screen and (min-width: #{$lg}) {
    @content;
  }
}
@mixin xl {
  @media screen and (min-width: #{$xl}) {
    @content;
  }
}
@mixin xxl {
  @media screen and (min-width: #{$xxl}) {
    @content;
  }
}

html {
  color: #13187c;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  @include lg {
    height: 100vh;
    overflow: hidden;
  }
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

header {
  background-color: white;
}

.content__container {
  height: calc(100vh - 120px);

  @include lg {
    overflow-y: auto;
  }
}

.content__scroll {
  min-height: 100%;

  @include lg {
    flex-direction: column;
    display: flex;
  }
}

footer {
  background-color: #13187c;
}

.sigContainer {
  width: 100%;
  height: 200px;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}

.buttons {
  width: 100%;
  height: 30px;
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
}

.bg-gray--left {
  position: relative;
  flex: 1;
  min-height: 75vh;

  &::before {
    content: "";
    background-color: #f4f7f9;
    width: 40%;
    height: 100%;
    top: 0;
    left: 0;
    display: block;
    position: absolute;
    z-index: -1;
    display: none;

    @include lg {
      display: block;
    }
  }
}

.blue-background {
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 8rem;
    left: 0;
    width: 0;
    height: 0;
    border-top-width: 125vh;
    border-top-style: solid;
    border-top-color: transparent;
    border-left-width: 315vh;
    border-left-style: solid;
    border-left-color: #13187c;
    // width: 100%;
    // height: 100%;
    // background-color: #13187c;
    // transform: rotate(-45deg);
    // transform-origin: center;
    display: none;
    
    @include lg {
      display: block;
    }
  }
}

// .thank-you__image {
//   max-width: 420px;
//   margin: 3rem auto;

//   @include lg {
//     margin: 3rem 0;
//     max-width: 100%;
//     transform: scale((1.15)) translateX(-15%);
//   }
// }
